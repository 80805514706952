import request from '@/common/request'


/** 
 * 灾害点管理
*/

export function getDangerList(pageindex,pagesize,filter){
    return request.post('/api/Danger/ListDannger', Object.assign({
        pageindex:pageindex,
        pagesize:pagesize?pagesize:20
    }, filter));
}

export function getDangerDetail(id){
    return request.post('/api/Danger/DanngerDetail/'+id, '');
}

export function delDanger(id){
    return request.post('/api/Danger/DeleteDannger/'+id, '');
}

export function addDanger(postdata){
    return request.post('/api/Danger/AddDannger', postdata);
}

export function editDanger(postdata){
    return request.post('/api/Danger/EditDannger', postdata);
}

export function delDangerImage(id){
    return request.post('/api/Danger/DeleteImage/'+id, '');
}
export function addDangerImage(postdata){
    return request.post('/api/Danger/AddImage', postdata);
}

export function editDangerImage(postdata){
    return request.post('/api/Danger/EditImage', postdata);
}

export function delDangerFile(id){
    return request.post('/api/Danger/DeleteFile/'+id, '');
}
export function addDangerFile(postdata){
    return request.post('/api/Danger/AddFile', postdata);
}

export function editDangerFile(postdata){
    return request.post('/api/Danger/EditFile', postdata);
}


/**
 * 监测点
 */

export function getMonitorList(pageindex,pagesize,filter){
    return request.post('/api/Monitor/ListMonitor', Object.assign({
        pageindex:pageindex,
        pagesize:pagesize?pagesize:20
    }, filter));
}

export function getMonitorDetail(id){
    return request.post('/api/Monitor/MonitorDetail/'+id, '');
}

export function getMonitorDevice(id){
    return request.post('/api/Device/MonitorDevice?monitorid='+id, '');
}

export function delMonitor(id){
    return request.post('/api/Monitor/DeleteMonitor/'+id, '');
}

export function addMonitor(postdata){
    return request.post('/api/Monitor/AddMonitor', postdata);
}

export function editMonitor(postdata){
    return request.post('/api/Monitor/EditMonitor', postdata);
}

export function delMonitorImage(id){
    return request.post('/api/Danger/DeleteImage/'+id, '');
}
export function addMonitorImage(postdata){
    return request.post('/api/Monitor/AddImage', postdata);
}

export function editMonitorImage(postdata){
    return request.post('/api/Danger/EditImage', postdata);
}

export function delMonitorFile(id){
    return request.post('/api/Danger/DeleteFile/'+id, '');
}
export function addMonitorFile(postdata){
    return request.post('/api/Monitor/AddFile', postdata);
}

export function editMonitorFile(postdata){
    return request.post('/api/Danger/EditFile', postdata);
}



/**
 * 承建单位
 */

export function getContractorList(pageindex,pagesize,filter){
    return request.post('/api/ContractUnit/ListContractUnit', Object.assign({
        pageindex:pageindex,
        pagesize:pagesize?pagesize:20
    }, filter));
}

export function delContractor(id){
    return request.post('/api/ContractUnit/DeleteContractUnit/'+id, '');
}

export function addContractor(postdata){
    return request.post('/api/ContractUnit/AddtContractUnit', postdata);
}

export function editContractor(postdata){
    return request.post('/api/ContractUnit/EditContractUnit', postdata);
}

export function defaultContractUnit(postdata) {
    return request.post('/api/ContractUnit/DefaultContractUnit', postdata);
}



/**
 * 运维单位管理
 */

export function getMaintainList(pageindex,pagesize,filter){
    return request.post('/api/MaintainUnit/ListMaintainUnit', Object.assign({
        pageindex:pageindex,
        pagesize:pagesize?pagesize:20
    }, filter));
}

export function delMaintain(id){
    return request.post('/api/MaintainUnit/DeleteMaintainUnit/'+id, '');
}

export function addMaintain(postdata){
    return request.post('/api/MaintainUnit/AddMaintainUnit', postdata);
}

export function editMaintain(postdata){
    return request.post('/api/MaintainUnit/EditMaintainUnit', postdata);
}

export function defaultMaintainUnit(postdata) {
    return request.post('/api/MaintainUnit/DefaultMaintainUnit', postdata);
}


export function getMaintainUserList(pageindex,pagesize,filter){
    return request.post('/api/MaintainUnit/ListUsers', Object.assign({
        pageindex:pageindex,
        pagesize:pagesize?pagesize:20
    }, filter));
}

export function delMaintainUser(id){
    return request.post('/api/MaintainUnit/DeleteUser/'+id, '');
}

export function addMaintainUser(postdata){
    return request.post('/api/MaintainUnit/AddUser', postdata);
}

export function editMaintainUser(postdata){
    return request.post('/api/MaintainUnit/EditUser', postdata);
}